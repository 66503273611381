<svelte:options tag="finance-calc" />

<script>
  import { afterUpdate } from "svelte";
  export let balloon_on = true;

  export let pcolor = "#0069b4";

  let popen = false;

  console.log(balloon_on);
  // Pruchase Price
  let price = 250000;
  // Deposit
  let deposit = 0;
  const fpm = 69;
  const frmcost = balloon_on ? 8600 : 5500;
  const initiationFee = 1207.5;
  $: maxDeposit = price - 30000;
  let interestRate = 13;
  let balloonPrct = 0;

  let duration = 72;

  $: loanAmount = price - deposit + frmcost + initiationFee;
  $: balloonRepayment = (price * balloonPrct) / 100;
  $: totalLoanAmount = loanAmount - balloonRepayment;
  $: emrpmnt = parseFloat(
    -PMT(interestRate / 100 / 12, duration, loanAmount, balloonRepayment * -1) +
      fpm
  ).toFixed(2);

  $: interestAmount = emrpmnt * duration - totalLoanAmount - fpm * duration;

  const paymentTerms = [12, 24, 36, 48, 54, 60, 72, 84, 96];

  $: PMT = function (ir, np, pv, fv, type) {
    console.log(ir, np, pv, fv, type);
    /*
     * ir   - interest rate per month
     * np   - number of periods (months)
     * pv   - present value
     * fv   - future value
     * type - when the payments are due:
     *        0: end of the period, e.g. end of month (default)
     *        1: beginning of period
     */
    var pmt, pvif;

    fv || (fv = 0);
    type || (type = 0);

    if (ir === 0) return -(pv + fv) / np;

    pvif = Math.pow(1 + ir, np);
    pmt = (-ir * (pv * pvif + fv)) / (pvif - 1);

    if (type === 1) pmt /= 1 + ir;

    return pmt;
  };

  const AppUpdateEvent = new CustomEvent("fcapp_updated", {
    bubbles: true,
    detail: {},
  });

  const formatCurrency = (value) => {
    return "R " + new Intl.NumberFormat().format(value);
  };

  afterUpdate(() => {
    AppUpdateEvent.detail["price"] = price;
    console.log(balloon_on);
    document.dispatchEvent(AppUpdateEvent);
  });
</script>

<div class="fca-row">
  <div class="fcacol-md-6">
    <div class="fca-field-group">
      <h2 for="fcp-price" style="text-align: center;display: block; color: {pcolor}">Vehicle Purchase Price:</h2>
      <div>
        <input id="fcp-price" type="number" bind:value={price} />
      </div>
    </div>
    <div class="fca-field-group">
      <div class="range-group">
        <div class="range-top">
          <label for="fcp-deposit">Deposit:</label>
          <div class="fca-field-prefix">
            <span>R</span>
            <input
              id="fcp-deposit"
              type="number"
              max={maxDeposit}
              bind:value={deposit}
            />
            </div>
        </div>
        <div class="range-bot">
          <input
            id="fcp-depositRange"
            type="range"
            max={maxDeposit}
            bind:value={deposit}
          />
        </div>
      </div>
    </div>
    <div class="fca-field-group">
      <div class="range-top select-group">
        <label for="">Payment Term:</label>
        <div class="fca-field-prefix">
          <span>Months</span>
          <select bind:value={duration}>
            {#each paymentTerms as month}
              <option  value={month}>
                {month}
              </option>
            {/each}
          </select>
        </div>
      </div>
    </div>
    {#if balloon_on}
      <div class="fca-field-group">
        <div class="range-group">
          <div class="range-top">
            <label for="fcp-balloon">Balloon Payment:</label>
            <div class="fca-field-prefix">
              <span>%</span>
            <input
              id="fcp-balloon"
              type="number"
              min="0"
              max="30"
              bind:value={balloonPrct}
            />
            </div>
          </div>
          <div class="range-bot">
            <input
              id="fcp-balloonRange"
              type="range"
              min="0"
              max="30"
              bind:value={balloonPrct}
            />
          </div>
        </div>
      </div>
    {/if}
    <div class="fca-field-group">
      <div class="range-group">
        <div class="range-top">
          <label for="fcp-interest">Interest Rate:</label>
          <div class="fca-field-prefix">
            <span>%</span>
          <input
            id="fcp-interest"
            type="number"
            min="7"
            max="17"
            bind:value={interestRate}
          />
          </div>
        </div>
        <div class="range-bot">
          <input
            id="fcp-price"
            type="range"
            min="7"
            max="17"
            step="0.25"
            bind:value={interestRate}
          />
        </div>
      </div>
    </div>
  </div>
  <div class="fcacol-md-6 fca-summary">
    <div>
      Estimated Monthly Instalment:
      <h3 style="color: {pcolor}">{formatCurrency(emrpmnt)}</h3>
      <p class="disclaimer-info">* Includes license-, documentation-, service and delivery-, monthly admin- and finance initiation fees.</p>
    </div>
    <table>
      <tr>
        <th>Breakdown</th>
        <th>Cost</th>
      </tr>
      <tr>
        <td> Amount to Finance: </td>
        <td>{formatCurrency(loanAmount)}</td>
      </tr>
      <tr>
        <td> Interest Amount: </td>
        <td>{formatCurrency(interestAmount)}</td>
      </tr>
      {#if balloon_on && balloonPrct != 0}
        <tr>
          <td> At the end of the payment term, you’ll owe: </td>
          <td>{formatCurrency(balloonRepayment)} <br /> plus interest</td>
        </tr>
      {/if}
      <tr>
        <td> Total Cost of Credit: </td>
        <td>{formatCurrency(emrpmnt * duration)}</td>
      </tr>
    </table>
    <div>
      <button class="disclaimer open-fca-popup" on:click|preventDefault={() => (popen = true)}>Disclaimer</button>
      {#if popen}
      <div class="fca-popup">
        <div class="fca-popup-body">
        <button class="close-fca-popup" on:click|preventDefault={() => (popen = false)}>
          <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21">
          <path id="Icon_material-close" data-name="Icon material-close" d="M28.5,9.615,26.385,7.5,18,15.885,9.615,7.5,7.5,9.615,15.885,18,7.5,26.385,9.615,28.5,18,20.115,26.385,28.5,28.5,26.385,20.115,18Z" transform="translate(-7.5 -7.5)"/>
          </svg>
        </button>
        <h4>Disclaimer</h4> 
        <div>
          <p>All calculations made on calculators supplied on this site, together with rates quoted, are guidelines only and are subject to confirmation at the time of finalising any transactions. All information regarding the products, fees and/or costs which are included in and form a fundamental basis of the calculations are subject to change at any time prior to a final pre-agreement quote being handed to the User.</p>                      
                                  
          <p>The User indemnifies Flip My Ride against any loss or liability, which the User may suffer as a result of the use of any calculator. The site and all information provided on this site and the services provided on this site, are provided "as is". The information provided on this site should not be treated as professional advice of any kind.</p>
        </div>
        </div>                       
      </div>
      {/if}
    </div>
  </div>
</div>

<style>
  button{
    border: 0;
    background-color: transparent;
    padding: 0;
  }
  .fca-popup{
    position: fixed;
    top: 120px;
    left: 50%;
    width: 600px;
    max-width: 90%;
    background-color: #ffffff;
    padding: 20px;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
    transform: translateX(-50%);
    z-index: 99999999;
  }
  .close-fca-popup{
    position: absolute;
    top: 20px;
    right: 20px;
  }
  .open-fca-popup{
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 30px;
    text-decoration: underline;
  }
  .open-fca-popup:hover, .close-fca-popup:hover{
    cursor: pointer;
  }
  .fca-summary {
    text-align: center;
  }
  .fca-summary table {
    text-align: left;
    max-width: 80%;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-spacing: 0;
  }
  .disclaimer-info{
    font-size: 14px;
    color: #545454;
    max-width: 80%;
    margin: 0 auto 30px;
  }
  .fca-summary table tr > * {
    border-bottom: 1px solid #cacaca;
    border-right: 1px solid #cacaca;
    vertical-align: top;
    padding: 8px 0;
  }
  .fca-summary table tr > :last-child {
    text-align: right;
    border-right: 0;
    border-left: 1px solid #cacaca;
  }
  .fca-summary table tr:last-child td {
    border-bottom: 0;
  }
  .fca-row {
    display: flex;
    flex-wrap: wrap;
  }
  .fca-row > * {
    box-sizing: border-box;
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
  }
  .range-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .fca-field-group {
    margin: 10px 0;
    padding: 5px 0;
  }
  input[type="range"] {
    width: 100%;
  }

  input:not([type="submit"]):not([type="range"]),
  select {
    font-size: 15px;
    margin-bottom: 0;
    margin-top: 0;
    padding: 8px;
    display: inline-block;
    max-width: 100%;
    width: 100%;
    color: #626262;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: inset 0 0 2px 2px rgba(0, 0, 0, 0.02);
    border: 1px solid #ebebeb;
    border-radius: 0;
    box-sizing: border-box;
  }

  .range-top input,
  .range-top select {
    width: 120px !important;
    max-width: 80%;
  }
  .fca-field-prefix{
    display: flex;
    align-items: center;
  }
  .fca-field-prefix span{
    font-size: 14px;
    margin-right: 2px;
    font-weight: 700;
  }
  .select-group {
    padding-bottom: 5px;
    border-bottom: 2px solid #c7c7c7;
  }
  .fca-summary h3 {
    font-size: 50px;
    margin-top: 10px;
    margin-bottom: 0px;
    line-height: 1.3;
  }
  @media (min-width: 768px) {
    .fcacol-md-6 {
      flex: 0 0 auto;
      width: 50%;
    }
  }
</style>
